<template>
    <div class="h-100 d-flex align-items-center justify-content-center">
        <va-card class="card box-shadow-card" width="913">
            <v-row>
                <v-col cols="12" md="4">
                    <v-img :src="require('@/assets/img/404.svg')"></v-img>
                </v-col>
                <v-col cols="12" md="8" class="my-auto">
                    <h1 class="display-4">
                        {{ $t("components.kApp404Activiteiten.activities_title") }}<br>
                        {{ $t("components.kApp404Activiteiten.activities_title_text") }}
                    </h1>
                    <k-spacing y="4"></k-spacing>
                    <v-list class="list" nav :items="activity">
                        <v-list-item class="activity-item-btn" v-for="item in activity" :key="item" :title="item.title"
                            :to="item.to">
                            <template v-slot:append>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                    fill="none">
                                    <path d="M12.175 7L6.575 1.4L8 0L16 8L8 16L6.575 14.6L12.175 9L0 9L0 7L12.175 7Z"
                                        fill="#908CC3" />
                                </svg>
                            </template>
                        </v-list-item>
                    </v-list>

                    <k-spacing y="4"></k-spacing>
                    <p>{{ $t("components.kApp404Activiteiten.activities_text") }}</p>
                    <k-spacing y="4"></k-spacing>
                    <v-btn href="/contact" color="var(--company-primary)" class=" btn text-white">
                        {{ $t("components.kApp404Activiteiten.activities_btn") }}</v-btn>
                </v-col>
            </v-row>
        </va-card>
    </div>
</template>



<script>

export default {


    data: () => ({
        activity: [
            {
                title: "Workshop mentale veerkracht",
                value: "",
                to: "/",
            },
            {
                title: "Timemanagement workshop: ",
                value: "",
                to: "/",
            },
            {
                title: "Timemanagement workshop: ",
                value: "",
                to: "/",
            },
        ],

    }),

};
</script>

<style>
.box-shadow-card {
    box-shadow: 0px 6px 24px 0px rgba(78, 78, 78, 0.08) !important;
}

.h-100 {
    height: 100vh;
}

.d-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.activity-item-btn {
    border-radius: var(--Radius--small, 10px);
    padding: var(--spacing-2, 8px);
    border: 1px solid var(--neutral-200, #DFDFDF);
    background: #FFF;
}

.activity-item-btn .v-list-item__overlay{
display: none;
}
</style>