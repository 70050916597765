<template>
  <div>
    <Dropzone
      v-if="file.length == 0"
      :accepted-types="'image/png, image/jpeg, image/svg+xml, image/webp'"
      @acceptFiles="handleUpload"
    >
      <div class="dropzone">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="37"
          height="36"
          viewBox="0 0 37 36"
          fill="none"
        >
          <path
            d="M4.49951 35.999C3.39951 35.999 2.45785 35.6074 1.67451 34.824C0.891178 34.0407 0.499512 33.099 0.499512 31.999V3.99902C0.499512 2.89902 0.891178 1.95736 1.67451 1.17402C2.45785 0.39069 3.39951 -0.000976562 4.49951 -0.000976562H32.4995C33.5995 -0.000976562 34.5412 0.39069 35.3245 1.17402C36.1078 1.95736 36.4995 2.89902 36.4995 3.99902V31.999C36.4995 33.099 36.1078 34.0407 35.3245 34.824C34.5412 35.6074 33.5995 35.999 32.4995 35.999H4.49951ZM4.49951 31.999H32.4995V3.99902H4.49951V31.999ZM6.49951 27.999H30.4995L22.9995 17.999L16.9995 25.999L12.4995 19.999L6.49951 27.999Z"
            fill="#DFDFDF"
          />
        </svg>
        <p>Kies bestand of sleep en plaats</p>
      </div>
      <template #dragActive>
        <div class="drag-active"><p>Drop het bestand hier</p></div>
      </template>
    </Dropzone>

    <div v-else>
      <cropper
        class="cropper"
        :src="image"
        :stencil-component="$options.components.CircleStencil"
        ref="cropper"
        image-restriction="stencil"
        :stencil-props="{
          aspectRatio: 9 / 9,
          movable: true,
          resizable: true,
        }"
        @change="change"
      />
    </div>

    <v-progress-linear indeterminate v-if="busy" />
    <v-btn
      elevation="0"
      v-if="file.length > 0"
      class="btn btn-sm"
      variant="outlined           btn-company-primary"
      color="var(--company-primary)"
      @click="file = null"
    >
      Wijzigen
    </v-btn>
    <k-spacing y="5"></k-spacing>
    <v-row>
      <v-col cols="12" class="d-flex">
        <v-btn
          class="btn"
          color="var(--company-primary)"
          variant="outlined"
          @click="$emit('close')"
        >
          {{ $t("views.account.overview.cancel_btn") }}
        </v-btn>
        <v-btn
          class="btn text-white ml-auto"
          color="var(--company-primary)"
          @click="submit()"
        >
          {{ $t("views.account.overview.save_btn") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Dropzone from "@/components/dropzone.vue";
import { Cropper } from "vue-advanced-cropper";
import "../../../../node_modules/vue-advanced-cropper/dist/style.css";
import { useMainStore } from "../../../stores/store";

import api from "../../../services/api";
import config from "../../../config";

export default {
  components: { Cropper, Dropzone },
  props: {
    user: Number,
    employer: Object,
    type: String,
    settings: Object,
    advisor: Object,
  },
  data: () => ({
    dialog: false,
    file: [],
    busy: false,
  }),
  computed: {
    // ...mapGetters({ token: 'gettoken', _user: 'getuser' }),
    image() {
      if (this.file !== null) {
        return URL.createObjectURL(this.file);
      } else {
        return "";
      }
    },
  },
  methods: {
    handleUpload(e) {
      this.file = e[0];
    },

    async getBlob(canvas) {
      const blob = await new Promise((resolve) => canvas.toBlob(resolve));
      return blob;
    },
    submit() {
      this.busy = true;
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        this.getBlob(canvas).then((blob) => {
          console.log(blob);
          const data = new FormData();
          data.append("file_image", blob);
          api
            .post(`${config.url.api}/v1/users/me/avatar`, data, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              if (res.data.success) {
                this.busy = false;
                this.$emit("close");
                this.$toast.success("Profielfoto bijgewerkt");
                api.get(`/v1/users/me/info`).then((res) => {
                  useMainStore().$patch({
                    user: res.data,
                  });
                });
              } else {
                this.$toast.error("Fout bij het bijwerken van de profielfoto");
              }
            })
            .catch(() => {
              this.$toast.error("Fout bij het bijwerken van de profielfoto");
            });
        });
      }
    },
  },
};
</script>
