import "./assets/scss/styles.scss";
import { createApp } from 'vue';
import App from './App.vue';
import setupInterceptors from './services/setupInterceptors';
import { createPinia } from 'pinia';

import router from './router';
import { useMainStore } from './stores/store';
import vuetify from './plugins/vuetify';

import { loadFonts } from './plugins/webfontloader';

const app = createApp(App);
/************************************************
 * Custom project components
 ************************************************/


import Contactcard from './components/contactcard';
app.component('contact-card', Contactcard);






import FileUploadField from './components/vapps/fileuploadfield';
import ColorPickerMenu from './components/colorpickermenu';
import Dashboardcard from './components/dashboardcard';
app.component('color-picker-menu', ColorPickerMenu);
app.component('dashboard-card', Dashboardcard);

/************************************************
 * Kyano apps components
 *
 * 1. THEME
 * 2. PERMISSIONS
 ************************************************/

/************************************************ 
 * 1. THEME
 ************************************************/

// App default logo
import KAppLogo from './components/framework/theme/k-app-logo';
app.component('k-app-logo', KAppLogo);

// App Sidebar
import KAppSidebar from './components/framework/theme/k-app-sidebar';
app.component('k-app-sidebar', KAppSidebar);

// App Topbar
import KAppTopbar from './components/framework/theme/k-app-topbar';
app.component('k-app-topbar', KAppTopbar);

// App 404
import KApp404 from './components/framework/theme/k-app-404';
app.component('k-app-404', KApp404);


// App 404 activiteiten
import KApp404A from './components/framework/theme/k-app-404-activiteiten';
app.component('k-app-404-a', KApp404A);




/************************************************ 
 * 2. PERMISSIONS
 ************************************************/

import KNoPermission from './components/framework/permissions/k-no-permission';
app.component('k-no-permission', KNoPermission);



import KSpacing from './components/framework/components/k-spacing';
app.component('k-spacing', KSpacing);


import KAvatarEdit from './components/framework/components/k-avatar-edit';
app.component('k-avatar-edit', KAvatarEdit);


import KFileupload from './components/framework/components/k-fileupload';
app.component('k-fileupload', KFileupload);

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
app.config.globalProperties.$toast = toast;

import FlagIcon from 'vue-flag-icon'

/************************************************
 * Vuetify v-apps components
 ************************************************/

import VaCard from './components/vapps/vacard';
import VaBtn from './components/vapps/vabtn';
import VaDialog from './components/vapps/vadialog';

// VCard
app.component('va-card', VaCard);
// VBtn
app.component('va-btn', VaBtn);

// VDialog
app.component('va-dialog', VaDialog);

// File upload field
app.component('file-upload-field', FileUploadField);

const pinia = createPinia();

setupInterceptors();
loadFonts();
import i18n from './i18n';

app.use(i18n);
app.use(FlagIcon);
app.use(router);
app.use(pinia);
app.use(useMainStore());
app.use(vuetify);

app.mount('#app');
