<template>
  <div :style="{ '--company-primary': companyprimarycolor }">
    <v-card :href="href" elevation="0" :to="to" class="contact-card rounded-xl">
      <v-row v-if="size === 'small'" class="">
        <v-col cols="12" class="px-0">
          <h3 class="text-company-primary text-center">
            {{ $t("components.contactcard.card_title") }}
          </h3>

          <k-spacing y="4"></k-spacing>
          <v-btn
            v-if="faq_btn"
            class="btn text-white"
            block
            color="var(--company-primary)"
            :to="{ path: '/contact' }"
            >{{ $t("components.contactcard.card_contact_btn") }}</v-btn
          >
          <k-spacing y="4"></k-spacing>
          <p class="ml-4">{{ $t("components.contactcard.card_text") }}</p>
        </v-col>
        <v-col cols="12" class="px-0">
          <v-list class="contactperson">
            <v-list-item :title="dfm_name">
              <template v-slot:prepend>
                <v-avatar
                  color="var(--company-primary)"
                  size="48"
                  :image="`${configdata.url.cdn}/empty-person.jpg`"
                ></v-avatar>
              </template>
            </v-list-item>
          </v-list>
          <v-list density="compact" class="contact-list mb-0 pb-0">
            <v-list-item v-if="dfm_phonenumber">
              <template v-slot:prepend>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M19.95 21.5C17.8667 21.5 15.8083 21.0458 13.775 20.1375C11.7417 19.2292 9.89167 17.9417 8.225 16.275C6.55833 14.6083 5.27083 12.7583 4.3625 10.725C3.45417 8.69167 3 6.63333 3 4.55C3 4.25 3.1 4 3.3 3.8C3.5 3.6 3.75 3.5 4.05 3.5H8.1C8.33333 3.5 8.54167 3.57917 8.725 3.7375C8.90833 3.89583 9.01667 4.08333 9.05 4.3L9.7 7.8C9.73333 8.06667 9.725 8.29167 9.675 8.475C9.625 8.65833 9.53333 8.81667 9.4 8.95L6.975 11.4C7.30833 12.0167 7.70417 12.6125 8.1625 13.1875C8.62083 13.7625 9.125 14.3167 9.675 14.85C10.1917 15.3667 10.7333 15.8458 11.3 16.2875C11.8667 16.7292 12.4667 17.1333 13.1 17.5L15.45 15.15C15.6 15 15.7958 14.8875 16.0375 14.8125C16.2792 14.7375 16.5167 14.7167 16.75 14.75L20.2 15.45C20.4333 15.5167 20.625 15.6375 20.775 15.8125C20.925 15.9875 21 16.1833 21 16.4V20.45C21 20.75 20.9 21 20.7 21.2C20.5 21.4 20.25 21.5 19.95 21.5ZM6.025 9.5L7.675 7.85L7.25 5.5H5.025C5.10833 6.18333 5.225 6.85833 5.375 7.525C5.525 8.19167 5.74167 8.85 6.025 9.5ZM14.975 18.45C15.625 18.7333 16.2875 18.9583 16.9625 19.125C17.6375 19.2917 18.3167 19.4 19 19.45V17.25L16.65 16.775L14.975 18.45Z"
                    fill="var(--company-primary)"
                  />
                </svg>
              </template>
              <v-list-item-title>
                <a :href="`tel:${dfm_phonenumber}`">
                  {{ dfm_phonenumber }}</a
                ></v-list-item-title
              >
            </v-list-item>
            <v-list-item v-if="dfm_email">
              <template v-slot:prepend>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M12 22.5C10.6167 22.5 9.31667 22.2375 8.1 21.7125C6.88333 21.1875 5.825 20.475 4.925 19.575C4.025 18.675 3.3125 17.6167 2.7875 16.4C2.2625 15.1833 2 13.8833 2 12.5C2 11.1167 2.2625 9.81667 2.7875 8.6C3.3125 7.38333 4.025 6.325 4.925 5.425C5.825 4.525 6.88333 3.8125 8.1 3.2875C9.31667 2.7625 10.6167 2.5 12 2.5C13.3833 2.5 14.6833 2.7625 15.9 3.2875C17.1167 3.8125 18.175 4.525 19.075 5.425C19.975 6.325 20.6875 7.38333 21.2125 8.6C21.7375 9.81667 22 11.1167 22 12.5V13.95C22 14.9333 21.6625 15.7708 20.9875 16.4625C20.3125 17.1542 19.4833 17.5 18.5 17.5C17.9167 17.5 17.3667 17.375 16.85 17.125C16.3333 16.875 15.9 16.5167 15.55 16.05C15.0667 16.5333 14.5208 16.8958 13.9125 17.1375C13.3042 17.3792 12.6667 17.5 12 17.5C10.6167 17.5 9.4375 17.0125 8.4625 16.0375C7.4875 15.0625 7 13.8833 7 12.5C7 11.1167 7.4875 9.9375 8.4625 8.9625C9.4375 7.9875 10.6167 7.5 12 7.5C13.3833 7.5 14.5625 7.9875 15.5375 8.9625C16.5125 9.9375 17 11.1167 17 12.5V13.95C17 14.3833 17.1417 14.75 17.425 15.05C17.7083 15.35 18.0667 15.5 18.5 15.5C18.9333 15.5 19.2917 15.35 19.575 15.05C19.8583 14.75 20 14.3833 20 13.95V12.5C20 10.2667 19.225 8.375 17.675 6.825C16.125 5.275 14.2333 4.5 12 4.5C9.76667 4.5 7.875 5.275 6.325 6.825C4.775 8.375 4 10.2667 4 12.5C4 14.7333 4.775 16.625 6.325 18.175C7.875 19.725 9.76667 20.5 12 20.5H17V22.5H12ZM12 15.5C12.8333 15.5 13.5417 15.2083 14.125 14.625C14.7083 14.0417 15 13.3333 15 12.5C15 11.6667 14.7083 10.9583 14.125 10.375C13.5417 9.79167 12.8333 9.5 12 9.5C11.1667 9.5 10.4583 9.79167 9.875 10.375C9.29167 10.9583 9 11.6667 9 12.5C9 13.3333 9.29167 14.0417 9.875 14.625C10.4583 15.2083 11.1667 15.5 12 15.5Z"
                    fill="var(--company-primary)"
                  />
                </svg>
              </template>
              <v-list-item-title>
                <a :href="`mailto:${dfm_email}`">
                  {{ dfm_email }}</a
                ></v-list-item-title
              >
            </v-list-item>
            <v-list-item v-if="dfm_address" :title="dfm_address">
              <template v-slot:prepend>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6 19H9V13H15V19H18V10L12 5.5L6 10V19ZM4 21V9L12 3L20 9V21H13V15H11V21H4Z"
                    fill="var(--company-primary)"
                  />
                </svg>
              </template>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row v-else class="">
        <v-col cols="12" md="6" class="text-center px-0">
          <h3 class="text-company-primary">
            {{ $t("components.contactcard.card_title") }}
          </h3>
          <k-spacing y="4"></k-spacing>
          <v-btn
            v-if="faq_btn"
            class="btn text-white"
            block
            color="var(--company-primary)"
            :to="{ path: '/contact' }"
            >{{ $t("components.contactcard.card_contact_btn") }}</v-btn
          >
          <k-spacing y="4"></k-spacing>
          <p>{{ $t("components.contactcard.card_text") }}</p>
        </v-col>
        <v-col cols="12" md="6" class="px-0">
          <v-list class="contactperson">
            <v-list-item :title="dfm_name">
              <template v-slot:prepend>
                <v-avatar
                  color="var(--company-primary)"
                  size="48"
                  :image="`${configdata.url.cdn}/empty-person.jpg`"
                ></v-avatar>
              </template>
            </v-list-item>
          </v-list>

          <v-list density="compact" class="contact-list mb-0 pb-0">
            <v-list-item v-if="dfm_phonenumber" class="contact-list-text">
              <template v-slot:prepend>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M19.95 21.5C17.8667 21.5 15.8083 21.0458 13.775 20.1375C11.7417 19.2292 9.89167 17.9417 8.225 16.275C6.55833 14.6083 5.27083 12.7583 4.3625 10.725C3.45417 8.69167 3 6.63333 3 4.55C3 4.25 3.1 4 3.3 3.8C3.5 3.6 3.75 3.5 4.05 3.5H8.1C8.33333 3.5 8.54167 3.57917 8.725 3.7375C8.90833 3.89583 9.01667 4.08333 9.05 4.3L9.7 7.8C9.73333 8.06667 9.725 8.29167 9.675 8.475C9.625 8.65833 9.53333 8.81667 9.4 8.95L6.975 11.4C7.30833 12.0167 7.70417 12.6125 8.1625 13.1875C8.62083 13.7625 9.125 14.3167 9.675 14.85C10.1917 15.3667 10.7333 15.8458 11.3 16.2875C11.8667 16.7292 12.4667 17.1333 13.1 17.5L15.45 15.15C15.6 15 15.7958 14.8875 16.0375 14.8125C16.2792 14.7375 16.5167 14.7167 16.75 14.75L20.2 15.45C20.4333 15.5167 20.625 15.6375 20.775 15.8125C20.925 15.9875 21 16.1833 21 16.4V20.45C21 20.75 20.9 21 20.7 21.2C20.5 21.4 20.25 21.5 19.95 21.5ZM6.025 9.5L7.675 7.85L7.25 5.5H5.025C5.10833 6.18333 5.225 6.85833 5.375 7.525C5.525 8.19167 5.74167 8.85 6.025 9.5ZM14.975 18.45C15.625 18.7333 16.2875 18.9583 16.9625 19.125C17.6375 19.2917 18.3167 19.4 19 19.45V17.25L16.65 16.775L14.975 18.45Z"
                    fill="var(--company-primary)"
                  />
                </svg>
              </template>
              <v-list-item-title>
                <a :href="`tel:${dfm_phonenumber}`">
                  {{ dfm_phonenumber }}</a
                ></v-list-item-title
              >
            </v-list-item>
            <v-list-item v-if="dfm_email" class="contact-list-text">
              <template v-slot:prepend>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M12 22.5C10.6167 22.5 9.31667 22.2375 8.1 21.7125C6.88333 21.1875 5.825 20.475 4.925 19.575C4.025 18.675 3.3125 17.6167 2.7875 16.4C2.2625 15.1833 2 13.8833 2 12.5C2 11.1167 2.2625 9.81667 2.7875 8.6C3.3125 7.38333 4.025 6.325 4.925 5.425C5.825 4.525 6.88333 3.8125 8.1 3.2875C9.31667 2.7625 10.6167 2.5 12 2.5C13.3833 2.5 14.6833 2.7625 15.9 3.2875C17.1167 3.8125 18.175 4.525 19.075 5.425C19.975 6.325 20.6875 7.38333 21.2125 8.6C21.7375 9.81667 22 11.1167 22 12.5V13.95C22 14.9333 21.6625 15.7708 20.9875 16.4625C20.3125 17.1542 19.4833 17.5 18.5 17.5C17.9167 17.5 17.3667 17.375 16.85 17.125C16.3333 16.875 15.9 16.5167 15.55 16.05C15.0667 16.5333 14.5208 16.8958 13.9125 17.1375C13.3042 17.3792 12.6667 17.5 12 17.5C10.6167 17.5 9.4375 17.0125 8.4625 16.0375C7.4875 15.0625 7 13.8833 7 12.5C7 11.1167 7.4875 9.9375 8.4625 8.9625C9.4375 7.9875 10.6167 7.5 12 7.5C13.3833 7.5 14.5625 7.9875 15.5375 8.9625C16.5125 9.9375 17 11.1167 17 12.5V13.95C17 14.3833 17.1417 14.75 17.425 15.05C17.7083 15.35 18.0667 15.5 18.5 15.5C18.9333 15.5 19.2917 15.35 19.575 15.05C19.8583 14.75 20 14.3833 20 13.95V12.5C20 10.2667 19.225 8.375 17.675 6.825C16.125 5.275 14.2333 4.5 12 4.5C9.76667 4.5 7.875 5.275 6.325 6.825C4.775 8.375 4 10.2667 4 12.5C4 14.7333 4.775 16.625 6.325 18.175C7.875 19.725 9.76667 20.5 12 20.5H17V22.5H12ZM12 15.5C12.8333 15.5 13.5417 15.2083 14.125 14.625C14.7083 14.0417 15 13.3333 15 12.5C15 11.6667 14.7083 10.9583 14.125 10.375C13.5417 9.79167 12.8333 9.5 12 9.5C11.1667 9.5 10.4583 9.79167 9.875 10.375C9.29167 10.9583 9 11.6667 9 12.5C9 13.3333 9.29167 14.0417 9.875 14.625C10.4583 15.2083 11.1667 15.5 12 15.5Z"
                    fill="var(--company-primary)"
                  />
                </svg>
              </template>
              <v-list-item-title class="contact-list-text">
                <a :href="`mailto:${dfm_email}`">
                  {{ dfm_email }}</a
                ></v-list-item-title
              >
            </v-list-item>
            <v-list-item
              class="contact-list-text"
              v-if="dfm_address"
              :title="dfm_address"
            >
              <template v-slot:prepend>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6 19H9V13H15V19H18V10L12 5.5L6 10V19ZM4 21V9L12 3L20 9V21H13V15H11V21H4Z"
                    fill="var(--company-primary)"
                  />
                </svg>
              </template>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { useMainStore } from "@/stores/store";
import config from "@/config";
export default {
  setup() {
    const mainStore = useMainStore();
    return {
      companyPrimaryColor: mainStore.companypage.primary_color,
      dfm_name: mainStore.companypage.data.contact.name
        ? mainStore.companypage.data.contact.name
        : "",
      dfm_phonenumber: mainStore.companypage.data.contact.phonenumber
        ? mainStore.companypage.data.contact.phonenumber
        : "",
      dfm_email: mainStore.companypage.data.contact.email
        ? mainStore.companypage.data.contact.email
        : "",
      dfm_address:
        mainStore.companypage.data.contact.address.line_1 &&
        mainStore.companypage.data.contact.address.city
          ? `${mainStore.companypage.data.contact.address.line_1} ${mainStore.companypage.data.contact.address.city}`
          : "",
    };
  },
  computed: {
    configdata() {
      return config;
    },
  },
  props: {
    href: String,
    to: String,
    image: String,
    title: String,
    content: String,
    size: String,
    faq_btn: Boolean,
  },
};
</script>

<style>
.contact-card {
  border: 1px solid var(--company-primary);
  padding: var(--spacing-6, 2.4rem);
}

.contact-card .v-list-item {
  padding: 0px 14px !important;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

.contact-card h3 {
  font-family: Dosis;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  /* 3.12rem */
}

.contact-card p,
.contact-card a {
  color: var(--neutral-700, #4e4e4e);
  font-size: 1.6rem;
}

.contact-card .contactperson .v-list-item-title {
  font-size: 1.6rem;
  line-height: 180%;
  font-weight: 600;
  color: var(--neutral-700, #4e4e4e);
}
.contact-list .v-list-item-title {
  color: var(--neutral-700, #4e4e4e);
  font-size: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  line-height: 180%;
}
</style>
