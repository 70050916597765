<template>
  <div :class="spacingClasses">
  
  </div>
</template>

<script>
export default {
  props: {
    y: {
      type: String,
      required: false,
      
    },
    x: {
      type: String,
      required: false,
      default: '',
    },
    yMd: {
      type: String,
      required: false,
      default: '',
    },
    xMd: {
      type: String,
      required: false,
      default: '',
    },
    yLg: {
      type: String,
      required: false,
      default: '',
    },
    xLg: {
      type: String,
      required: false,
      default: '',
    },
  }, 
  computed: {
    spacingClasses() {
      return {
        [`--spacing-x-${this.x}`]: !!this.x,
        [`--spacing-y-${this.y}`]: !!this.y,
        [`--spacing-x-md-${this.xMd}`]: !!this.xMd,
        [`--spacing-y-md-${this.yMd}`]: !!this.yMd,
        [`--spacing-x-lg-${this.xLg}`]: !!this.xLg,
        [`--spacing-y-lg-${this.yLg}`]: !!this.yLg,
      };
    }, 
  },
};
</script>
