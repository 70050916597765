<template>
  <router-view v-if="ready" />
</template>

<script>
const cookies = require("js-cookie");

import api from "./services/api";
import config from "./config/index";
import { useMainStore } from "./stores/store";

export default {
  components: {},
  data: () => ({
    ready: false,
  }),
  methods: {
    setCompanyPrimaryColor(color) {
      document.documentElement.style.setProperty("--company-primary", color);
    },
  },

  beforeMount() {
    var isset_language = false;
    let subdomain = location.hostname.split(".", 1);
    let config_login = config.url.login.replace("{{domain}}", subdomain[0]);
    if (
      !location.pathname.startsWith("/inloggen") &&
      !location.pathname.startsWith("/registreren") &&
      !location.pathname.startsWith("/welcome") &&
      !location.pathname.startsWith("/registratie-voltooien") &&
      !location.pathname.startsWith("/wachtwoord-wijzigen") &&
      !location.pathname.startsWith("/wachtwoord-vergeten")
    ) {
      if (cookies.get("fmw_refresh_token")) {
        api
          .get(`/v1/users/me/info`)
          .then((res) => {
            useMainStore().$patch({
              user: res.data,
            });
            if (res.data.language) {
              isset_language = true;
              this.$i18n.locale = res.data.language;
            }
            api
              .get(`/v1/pages/${subdomain[0]}`)
              .then((res) => {
                this.setCompanyPrimaryColor(
                  res.data.success.style.primary_color
                );
                this.ready = true;
                if (!isset_language) {
                  if (res.data.success.preferred_language == "eng") {
                    this.$i18n.locale = "en";
                  } else {
                    this.$i18n.locale = "nl";
                  }
                }
                useMainStore().$patch({
                  companypage: res.data.success,
                });
              })
              .catch(() => {
                this.ready = true;

                cookies.remove("fmw_access_token", {
                  domain: config.cookiedomain,
                  path: "/",
                });
                cookies.remove("fmw_refresh_token", {
                  domain: config.cookiedomain,
                  path: "/",
                });
                cookies.remove("user", {
                  domain: config.cookiedomain,
                  path: "/",
                });
                location.replace(
                  `${config_login}/inloggen?r=${encodeURI(location.href)}`
                );
              });
          })
          .catch((err) => {
            if (err.response.status === 401) {
              cookies.remove("fmw_access_token", {
                domain: config.cookiedomain,
                path: "/",
              });
              cookies.remove("fmw_refresh_token", {
                domain: config.cookiedomain,
                path: "/",
              });
              cookies.remove("user", {
                domain: config.cookiedomain,
                path: "/",
              });
              location.replace(
                `${config_login}/inloggen?r=${encodeURI(location.href)}`
              );
            } else {
              location.replace(
                `${config_login}/inloggen?r=${encodeURI(location.href)}`
              );
            }
          });
      } else {
        api
          .get(`/v1/pages/${subdomain[0]}`)
          .then((res) => {
            this.setCompanyPrimaryColor(res.data.success.style.primary_color);
            useMainStore().$patch({
              companypage: res.data.success,
            });
            if (!isset_language) {
              if (res.data.success.preferred_language == "eng") {
                this.$i18n.locale = "en";
              } else {
                this.$i18n.locale = "nl";
              }
            }
            this.ready = true;
            this.$router.push("/bedrijfspagina");
          })
          .catch(() => {
            this.ready = true;

            cookies.remove("fmw_access_token", {
              domain: config.cookiedomain,
              path: "/",
            });
            cookies.remove("fmw_refresh_token", {
              domain: config.cookiedomain,
              path: "/",
            });
            cookies.remove("user", {
              domain: config.cookiedomain,
              path: "/",
            });
            location.replace(
              `${config_login}/inloggen?r=${encodeURI(location.href)}`
            );
          });
      }
    } else {
      api
        .get(`/v1/pages/${subdomain[0]}`)
        .then((res) => {
          this.setCompanyPrimaryColor(res.data.success.style.primary_color);
          useMainStore().$patch({
            companypage: res.data.success,
          });
          if (!isset_language) {
            if (res.data.success.preferred_language == "eng") {
              this.$i18n.locale = "en";
            } else {
              this.$i18n.locale = "nl";
            }
          }
          this.ready = true;
        })
        .catch(() => {
          this.ready = true;
          cookies.remove("fmw_access_token", {
            domain: config.cookiedomain,
            path: "/",
          });
          cookies.remove("fmw_refresh_token", {
            domain: config.cookiedomain,
            path: "/",
          });
          cookies.remove("user", {
            domain: config.cookiedomain,
            path: "/",
          });
          location.replace(
            `${config_login}/inloggen?r=${encodeURI(location.href)}`
          );
        });
    }
  },
};
</script>

<style>
.text-company-primary {
  color: var(--company-primary) !important;
}
</style>
